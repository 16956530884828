import { gql } from '@/core/shared/api';

export async function getOrganizationAnalytics (startTime, endTime, callback) {
  const query = `query organizationAnalytics ($startTime: String, $endTime: String) {
    organizationAnalytics (startTime: $startTime, endTime: $endTime) {
      minutesPlayed
      averageSessionLength
      numberOfSessions
      locationUtilization @defer {
        labels
        data
      }
      dayOfWeekUsage @defer {
        labels
        data
      }
      hourOfDayUsage @defer {
        labels
        data
      }
      gameUsage @defer {
        labels
        data
      }
    }
  }`;

  await gql.requestWithDefer(query, {
    startTime,
    endTime,
  }, {}, callback);
}
