import Vue from 'vue';
import { reactive, toRefs, computed } from '@vue/composition-api';
import { useStore, useI18n } from '@/core/shared/compositions';
import { getOrganizationAnalytics } from './actions';
import { useChartJSOptions } from '@/core/shared/compositions/useChartJSOptions';

export function useOrganizationAnalytics () {
  const store = useStore();
  const i18n = useI18n();

  const {
    colorArray,
    getRainbowDataset,
    getSolidColorDataset,
    getDefaultRadialChart,
    getDefaultBarChart,
    localizeData,
  } = useChartJSOptions();

  const analytics = reactive({
    minutesPlayed: null,
    averageSessionLength: null,
    numberOfSessions: null,
    locationUtilization: null,
    dayOfWeekUsage: null,
    hourOfDayUsage: null,
    gameUsage: null,
  });

  function resetAnalytics () {
    Object.keys(analytics).forEach(key => {
      analytics[key] = null;
    });
  }

  function initVisualiztion (rawData, visualizationType) {
    if (visualizationType === 'pie') return getDefaultRadialChart(rawData.labels);
    if (visualizationType === 'bar') return getDefaultBarChart(rawData.labels);
  }

  function getDataset (rawData, visualizationType, index, dateRangesLength) {
    if (dateRangesLength === 1) {
      if (visualizationType === 'pie') return getRainbowDataset(rawData.data);
      if (visualizationType === 'bar') return getSolidColorDataset(rawData.data);
    } else {
      return getSolidColorDataset(rawData.data, index);
    }
  }

  function addToAnalytic (rawData, analyticName, visualizationType, index, dateRangesLength) {
    if (!rawData) return;

    if (!analytics[analyticName]) {
      analytics[analyticName] = initVisualiztion(rawData, visualizationType);
    }

    const newAnalytic = { ...analytics[analyticName] };
    newAnalytic.data.labels = rawData.labels;
    newAnalytic.data.datasets[index] = getDataset(rawData, visualizationType, index, dateRangesLength);
    analytics[analyticName] = newAnalytic;
  }

  function onDataRecieved ({ organizationAnalytics }, done, index, dateRangesLength) {
    Object.keys(organizationAnalytics).forEach(analyticName => {
      const rawData = organizationAnalytics[analyticName];
      const localizedData = localizeData(rawData);

      if (
        analyticName === 'locationUtilization' ||
        analyticName === 'gameUsage'
      ) {
        addToAnalytic(localizedData, analyticName, 'pie', index, dateRangesLength);
      } else if (
        analyticName === 'dayOfWeekUsage' ||
        analyticName === 'hourOfDayUsage'
      ) {
        addToAnalytic(localizedData, analyticName, 'bar', index, dateRangesLength);
      } else {
        if (!analytics[analyticName]) analytics[analyticName] = [];
        Vue.set(analytics[analyticName], index, localizedData);
      }
    });

    if (done) store.commit('setLoading', false);
  }

  async function doGetOrganizationAnalytics (dateRanges) {
    store.commit('setLoading', true);

    resetAnalytics();

    return Promise.all(dateRanges.map((dateRange, index) => {
      return getOrganizationAnalytics(
        dateRange.startTime,
        dateRange.endTime,
        (data, done) => onDataRecieved(data, done, index, dateRanges.length),
      );
    }));
  }

  const tooltips = computed(() => ({
    minutesPlayed: i18n.t('Total minutes played on all stations within the chosen date range.'),
    averageSessionLength: i18n.t('Average game session length in minutes on all stations within the chosen date range. A game session represents a launch and then an exit of a piece of content.'),
    numberOfSessions: i18n.t('Total game sessions on all stations within the chosen date range. A game session represents a launch and then an exit of a piece of content.'),
    dayOfWeekUsage: i18n.t('Total minutes on all stations within the chosen date range grouped by day of week.'),
    hourOfDayUsage: i18n.t('Total minutes on all stations within the chosen date range grouped by hour.'),
    locationUtilization: i18n.t('Total minutes played at all locations within the chosen date range grouped by location.'),
    gameUsage: i18n.t('Total minutes played at all locations within the chosen date range grouped by the top 10 most played content.'),
  }));

  return {
    colorArray,
    doGetOrganizationAnalytics,
    ...toRefs(analytics),
    tooltips,
  };
}
