<template>
  <div class="organization-analytics-route">
    <Panel>
      <div class="dateselectorwrapper">
        <div class="dateselector">
          <div class="dateranges">
            <div v-for="(dateRange, index) in dateRanges" :key="index" class="daterange" :class="{ '-threegrid': dateRanges.length > 1 }">
              <div class="color" v-if="dateRanges.length > 1" :style="{ 'background-color': colorArray[index] }"></div>
              <DateRange :dateRange="dateRange" @update:dateRange="updateDateRange(index, $event)" />
            </div>
          </div>
          <div class="buttons">
            <UIButton tiny @click="addDateRange" v-if="dateRanges.length === 1">{{ $t('Compare Dates') }}</UIButton>
            <UIButton tiny @click="removeDateRange(1)" v-else>{{ $t('Remove Comparing Dates') }}</UIButton>
            <UIButton class="export" @click="refreshAnalytics">{{ $t('Refresh Data') }}</UIButton>
          </div>
        </div>
      </div>
    </Panel>
    <div class="container">
      <div class="bignumbers">
        <BigNumberStatPanel
          :title="$t('Minutes Played')"
          :numbers="minutesPlayed"
          :colors="dateRanges.length > 1"
          :loading="!minutesPlayed || refreshing"
          :tooltip="tooltips.minutesPlayed"
        />
        <BigNumberStatPanel
          :title="$t('Average Game Session Length in Minutes')"
          :numbers="averageSessionLength"
          :colors="dateRanges.length > 1"
          :loading="!averageSessionLength || refreshing"
          :tooltip="tooltips.averageSessionLength"
        />
        <BigNumberStatPanel
          :title="$t('Number of Game Sessions')"
          :numbers="numberOfSessions"
          :colors="dateRanges.length > 1"
          :loading="!numberOfSessions || refreshing"
          :tooltip="tooltips.numberOfSessions"
        />
      </div>
      <div class="smallcharts">
        <ChartJSStatPanel
          :title="$t('Usage by Day of Week')"
          :chartOptions="dayOfWeekUsage"
          :loading="!dayOfWeekUsage || refreshing"
          :tooltip="tooltips.dayOfWeekUsage"
        />
        <ChartJSStatPanel
          :title="$t('Usage by Hour')"
          :chartOptions="hourOfDayUsage"
          :loading="!hourOfDayUsage || refreshing"
          :tooltip="tooltips.hourOfDayUsage"
        />
      </div>
      <div class="bigcharts">
        <ChartJSStatPanel
          :title="$t('Location Usage')"
          :chartOptions="locationUtilization"
          :loading="!locationUtilization || refreshing"
          :tooltip="tooltips.locationUtilization"
        />
        <ChartJSStatPanel
          :title="$t('Top 10 Content Usage')"
          :chartOptions="gameUsage"
          :loading="!gameUsage || refreshing"
          :tooltip="tooltips.gameUsage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { ref } from '@vue/composition-api';
import { useOrganizationAnalytics } from './compositions/useOrganizationAnalytics';
import BigNumberStatPanel from '@/core/shared/components/analytics/stat-panels/BigNumberStatPanel';
import ChartJSStatPanel from '@/core/shared/components/analytics/stat-panels/ChartJSStatPanel';
import UIButton from '@/core/shared/components/ui/UIButton';
import Panel from '@/core/shared/components/Panel';
import DateRange from '@/core/shared/components/analytics/DateRange';
import moment from 'moment-timezone';
import { useStore, useI18n } from '@/core/shared/compositions';

export default {
  components: {
    BigNumberStatPanel,
    ChartJSStatPanel,
    DateRange,
    UIButton,
    Panel,
  },
  setup (props) {
    const store = useStore();
    const i18n = useI18n();

    store.commit('setTitle', i18n.t('Organization Analytics'));

    const {
      doGetOrganizationAnalytics,
      minutesPlayed,
      averageSessionLength,
      numberOfSessions,
      locationUtilization,
      dayOfWeekUsage,
      hourOfDayUsage,
      gameUsage,
      colorArray,
      tooltips,
    } = useOrganizationAnalytics();

    const refreshing = ref(false);

    const dateRanges = ref([]);

    function addDateRange () {
      dateRanges.value = [
        ...dateRanges.value,
        {
          startTime: moment().date(1).format(),
          endTime: moment().format(),
        },
      ];
    }

    function updateDateRange (index, dateRange) {
      Vue.set(dateRanges.value, index, dateRange);
    }

    function removeDateRange (index) {
      dateRanges.value = dateRanges.value.slice(0, index).concat(dateRanges.value.slice(index + 1));
    }

    async function refreshAnalytics () {
      refreshing.value = true;
      await doGetOrganizationAnalytics(dateRanges.value);
      refreshing.value = false;
    };

    addDateRange();
    doGetOrganizationAnalytics(dateRanges.value);

    return {
      dateRanges,
      addDateRange,
      updateDateRange,
      removeDateRange,

      minutesPlayed,
      averageSessionLength,
      numberOfSessions,
      locationUtilization,
      dayOfWeekUsage,
      hourOfDayUsage,
      gameUsage,

      refreshing,
      refreshAnalytics,

      colorArray,
      tooltips,
    };
  },
};
</script>

<style lang="postcss" scoped>
@import "core/shared/styles";

.organization-analytics-route {
  & .container {
    margin-top: var(--spacingMd);
    display: grid;
    grid-gap: var(--spacingMd);
  }

  & .dateselectorwrapper {
    display: flex;
    justify-content: center;

    & .dateselector {
      flex: 1 0 100%;
      max-width: 120rem;
    }

    & .dateranges {
      margin-bottom: var(--spacingSm);
      display: grid;
      grid-gap: var(--spacingSm);

      & .daterange {
        position: relative;

        & .close {
          position: absolute;
          width: 2rem;
          height: 2rem;
          top: calc(var(--spacingSm) * -1);
          right: calc(var(--spacingSm) * -1);
        }

        & .color {
          width: 3rem;
          height: 3rem;
        }

        &.-threegrid {
          display: grid;
          grid-gap: var(--spacingSm);
          align-items: center;
          grid-template-columns: auto 1fr;
        }
      }
    }

    & .buttons {
      display: grid;
      grid-gap: var(--spacingMd);

      @media (--tablet) {
        grid-template-columns: auto auto;
        justify-content: end;
      }
    }
  }

  & .bignumbers {
    display: grid;
    grid-gap: var(--spacingMd);

    @media (--desktop) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  & .smallcharts {
    display: grid;
    grid-gap: var(--spacingMd);

    @media (--desktop) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  & .bigcharts {
    display: grid;
    grid-gap: var(--spacingMd);

    @media (--desktopXL) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  & .exportbuttons {
    display: grid;
    grid-gap: var(--spacingMd);
    align-items: end;

    @media (--tablet) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
</style>
