<template>
  <div class="organization-settings container">
    <ValidationObserver ref="validationObserver" v-slot="{ pristine, valid }">
      <OrganizationDetailsForm
        :organization="organization"
        :countries="countries"
        :regions="regions"
        :loading="loading"
        @edit:organization="editOrganization"
      />
      <BottomBar
        @save="storeOrganization"
        :valid="!pristine && valid"
        :saving="saving"
        :backText="$t('Back')"
        :backRouterLink="{ name: 'home' }"
      />
    </ValidationObserver>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import ContentBlock from '@/core/shared/components/ContentBlock';
import FocusModeMixin from '@/core/shared/misc/FocusModeMixin';
import BottomBar from '@/core/shared/components/BottomBar';
import OrganizationDetailsForm from '@/admin/OrganizationDetailsForm';
import { ValidationObserver } from 'vee-validate';

import { isEqual } from 'lodash-es';

@Component({
  components: {
    ContentBlock,
    BottomBar,
    OrganizationDetailsForm,
    ValidationObserver,
  },
})
export default class OrganizationSettings extends mixins(FocusModeMixin) {
  organizationChanges = {};
  saving = false;

  get organization () {
    return { ...this.organizationInStore, ...this.organizationChanges };
  }

  get organizationInStore () {
    return this.$store.getters.currentOrganization;
  }

  editOrganization (property, value) {
    const dirty = !isEqual(this.organizationInStore[property], value);
    if (dirty) this.$set(this.organizationChanges, property, value);
    else this.$delete(this.organizationChanges, property);
  }

  get countries () {
    return this.$store.getters['entities/Country/all']();
  }

  get regions () {
    return this.$store.getters['entities/Region/all']();
  }

  get loading () {
    return this.$store.state.organization.isFetchingOrganization;
  }

  created () {
    this.$store.commit('setTitle', this.$t('Organization'));
    this.$store.dispatch('getOrganizationDetailsForForm');
    this.$store.dispatch('getCountries');
    this.$store.dispatch('getRegions');
  }

  async validateTaxNumber () {
    // Only validate while saving, to prevent calling VATLayer with parts of VAT numbers
    if (this.saving) {
      const vatNumberExempt = this.organizationChanges.vatNumberExempt ? this.organizationChanges.vatNumberExempt : this.organization.vatNumberExempt;
      if (!vatNumberExempt) {
        const taxNumber = this.organizationChanges.taxNumber ? this.organizationChanges.taxNumber : this.organization.taxNumber;
        const { validateVatNumber } = await this.$store.dispatch('validateVatNumber', { organizationId: this.organization.id, vatNumber: taxNumber });
        if (validateVatNumber === -1) { // Invalid
          this.$store.commit('setFlash', {
            type: 'error',
            message: this.$t('The VAT number is invalid'),
          });
        } else if (validateVatNumber === -2) { // Service error
          this.$store.commit('setFlash', {
            type: 'error',
            message: this.$t('The VAT number validation service could not be reached'),
          });
        }
        return validateVatNumber === 1;
      }
    }
    return true;
  }

  async storeOrganization () {
    this.saving = true;
    const isFormValid = await this.$refs.validationObserver.validateWithInfo().then(({ isValid, errors }) => {
      return isValid;
    });
    const isTaxNumberValid = await this.validateTaxNumber();
    if (isFormValid && isTaxNumberValid) {
      try {
        await this.$store.dispatch('storeOrganization', { id: this.organization.id, ...this.organizationChanges });
        this.$store.commit('setFlash', {
          type: 'success',
          message: `${this.organization.name} ${this.$t('Saved')}`,
        });
        this.$router.push({ name: 'home' });
      } finally {
        this.saving = false;
      }
    }
    this.saving = false;
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.organization-settings {
  padding-top: var(--spacingMd);
}
</style>
